.button-group-container {
  display: flex;
  justify-content: space-between;
  .customButton {
    width: 40px;
    height: 40px;
    letter-spacing: 0.5px;
    background-color: #f5f5f5;
    color: black;
    font-size: 12px;
    border: 1.5px solid #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
  }

  .active {
    background-color: rgba(226, 14, 2, 1);
    border: 1.5px solid rgba(226, 14, 2, 1);
    color: white;
  }
}
