/*------------- Variable ------------*/

:root {
    --iq-title-text: #ffffff;
    --iq-body-text: #d1d0cf;
    --iq-body-bg: #141414;
    --iq-primary: #e20e02;
    --iq-secondary: #83878a;
    --iq-success: #14e788;
    --iq-warning: #f68a04;
    --iq-danger: #545e75;
    --iq-info: #007aff;
    --iq-dark: #000000;
    --iq-light: #fafafb;
    --iq-white: #ffffff;
    --iq-black: #000;
    --iq-gray: #b1b1b1;
    --iq-dark1: #a2a4af;
    --iq-light-primary: rgba(226, 14, 2, 0.2);
    --iq-light-secondary: rgba(131, 135, 138, 0.2);
    --iq-light-success: rgba(20, 231, 136, 0.2);
    --iq-light-danger: rgba(84, 94, 117, 0.2);
    --iq-light-warning: rgba(246, 138, 4, 0.2);
    --iq-light-info: rgba(0, 122, 255, 0.2);
    --iq-light-light: rgba(250, 250, 251, 0.2);
    --iq-light-dark: rgba(0, 0, 0, 0.2);
    --iq-primary-hover: #bf000a;
    --iq-secondary-hover: #807e7e;
    --iq-success-hover: #42f9d9;
    --iq-danger-hover: #3c4354;
    --iq-warning-hover: #fdb45e;
    --iq-info-hover: #c280ea;
    --iq-dark-hover: #44454e;
    --iq-light-hover: #eaeeff;
    --iq-border: #141414;
    --iq-border-light: #141414;
    --iq-border-danger: #ff9273;
    --iq-light-card: #191919;
    --iq-primary-rgb: 100, 114, 248;
    --iq-primary-light: #f3fffe;
    --iq-bg1: #363636;
    --iq-disable: #313946;

    /* Light Thema */

    --iq-body-bg-light: rgba(20, 20, 20, 0.11);
    --iq-card-divider-light: rgba(20, 20, 20, 0.03);
    --iq-border-bg-light: rgba(0, 0, 0, 0.13);
    --iq-body-bg-primary-light: rgba(250, 250, 250, 0.99);
    --iq-input-text-light: #a2a3a2;
    --iq-paragraph-light: rgba(22, 22, 22, 1);
    --iq-sidebar-light: rgba(255, 255, 255, 1);
    --iq-sidebar-text-light: rgba(25, 25, 25, 1);
    --iq-sidebar-search-light: rgba(233, 238, 241, 0.56);
    --iq-inner-body-light: #f5f5f5;
    --iq-inner-card-light: rgba(254, 254, 254, 1);
    --iq-user-table-text-light: rgba(20, 20, 20, 1);
    --iq-project-button-light: rgba(226, 14, 2, 1);
    --iq-dashboard-text-light: rgba(25, 25, 25, 0.6);
}
