#sign-in-page-box {
    background: #fff;
    border-radius: 10px;
    position: relative;
    width: 100%;
    min-height: 480px;
    height: 93vh;
    margin: 15px auto;
    box-shadow: 0px 4px 20px 0px rgba(44, 101, 144, 0.1);
    -webkit-box-shadow: 0px 4px 20px 0px rgba(44, 101, 144, 0.1);
}
.sign-in-detail {
    color: var(--iq-white);
}
.sign-in-page {
    height: 100vh;
    position: relative;
    background: url("../../images/login/login.png") no-repeat scroll 0 0;
    background-size: cover;
    .sign-in-container {
        height: 100vh;
        width: 100vw;
        background-color: rgba($color: #000000, $alpha: 0.2);
    }
}
.sign-in-page .height-self-center {
    height: 100vh;
    border-radius: 15px;
}
.sign-in-page-data {
    border-radius: 15px;
}
.sign-in-detail {
    padding: 50px 80px;
}
.sign-in-logo {
    display: inline-block;
    width: 100%;
}
.sign-in-logo img {
    height: 50px;
}

/*.sign-in-from {position: relative; }*/
.sign-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.iq-social-media {
    margin: 0;
    padding: 0;
    float: right;
}
.iq-social-media li {
    list-style: none;
    float: left;
    margin-right: 10px;
}
.iq-social-media li:last-child {
    margin-right: 0;
}
.iq-social-media li a {
    height: 30px;
    width: 30px;
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    display: inline-block;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    background: var(--iq-light-primary);
    color: var(--iq-primary) !important;
}
.iq-social-media li a:hover {
    text-decoration: none;
}
.sign-in-page .btn {
    padding: 10px 35px;
    border-radius: 5px;
}
.sign-user_card {
    position: relative;
    background: var(--iq-body-bg-primary-light);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    display: block;
    margin: 0 auto;
}
.sign-user_logo {
    position: absolute;
    top: -56px;
    border-radius: 50%;
    padding: 10px;
    text-align: center;
}
.sign-user_logo img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 2px solid white;
}
.sign-in-page .form-control,
.m-profile .form-control {
    color: var(--iq-input-text-light);
}
.sign-in-page .form-control:focus,
.m-profile .form-control:focus {
    background: transparent;
    box-shadow: none;
    border: 1px solid var(--iq-primary);
}
::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    /* color: #f00c0c !important; opacity: 1; */
    color: var(--iq-input-text-light) !important;
    /* Firefox */
}
:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    /* color: #d9d5d5 !important;  */
    color: var(--iq-input-text-light) !important;
}
::-ms-input-placeholder {
    /* Microsoft Edge */
    /* color: #d9d5d5 !important; */
    color: var(--iq-input-text-light) !important;
}
.f-link:hover {
    color: var(--iq-primary);
}
