.iq-movie-card-body {
    padding: 0% !important;
}
.movie-heading {
    color: var(--iq-sidebar-text-light);
    font-size: 30px;
    opacity: 0.6;
}
.movie-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 120px;
}
.movie-title {
    color: var(--iq-user-table-text-light);
    font-size: 15px;
    opacity: 0.6;
}
.iq-card {
    // padding-bottom: 55px !important;
}
// .player-wrapper {
//     position: relative;
//     padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
// }

// .react-player {
//     position: absolute;
//     top: 0;
//     left: 0;
// }
