.iq-movie-card-body {
    padding: 0% !important;
}
.movie-heading {
    color: var(--iq-sidebar-text-light);
    font-size: 30px;
    opacity: 0.6;
}
.movie-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 120px;
    opacity: 0.6;
}
.movie-title {
    color: var(--iq-user-table-text-light);
    font-size: 15px;
}
.iq-card {
    padding-bottom: 55px !important;
}
// album
.iq-card {
    padding-bottom: 55px !important;
}
.iq-card {
    padding-bottom: 0px !important;
}

.AudioTrack {
    box-sizing: border-box;
    display: flex;
    height: 65px;
    padding: 15px;
    color: #111111 !important;
    justify-content: center;
    border: 1px dashed rgba(17, 17, 17, 0.4);
}
.albumImage {
    height: 70%;
    background-color: green;
}
.player {
    width: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

/// NewPlayer
.bar__progress {
    width: 100%;
    border-radius: 5px;
    margin: 0 20px;
    height: 10px;
    cursor: pointer;
}
// .rhap_container {
//     background-color: rgba($color: var(--iq-project-button-light), $alpha: 0.3) !important;
//     height: 100% !important;
// }

.rhap_container svg {
    color: var(--iq-project-button-light);
}
.rhap_progress-indicator {
    background: var(--iq-project-button-light) !important;
}
.rhap_progress-filled {
    background: var(--iq-project-button-light) !important;
}
.rhap_volume-indicator {
    background: var(--iq-project-button-light) !important;
}
